var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayShow
    ? _c("main", [
        _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { id: "'breadcrumb0", to: { name: "AddSubAccount" } }
                  },
                  [
                    _c("span", { attrs: { to: "" } }, [
                      _vm._v(_vm._s(_vm.$t("MANAGEMENT.MANAGEMENT")))
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      id: "'breadcrumb1",
                      to: { name: "SubAccountList" }
                    }
                  },
                  [
                    _c("span", { attrs: { to: "" } }, [
                      _vm._v(_vm._s(_vm.$t("MANAGEMENT.SUB_ACCOUNT")))
                    ])
                  ]
                )
              ],
              1
            ),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _c("span", { attrs: { to: "" } }, [
                _vm._v(_vm._s(_vm.$t("MANAGEMENT.ADD_SUB_ACCOUNT")))
              ])
            ])
          ])
        ]),
        _c("div", [
          _c("h1", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.ADD_SUB_ACCOUNT")))]),
          _c("div", { staticClass: "section-group" }, [
            _c(
              "form",
              {
                staticClass: "forms-sample",
                on: {
                  submit: function($event) {
                    return _vm.save($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "mb-4" }, [
                  _c("div", { staticClass: "form row" }, [
                    _c("div", { staticClass: "form-group col-5" }, [
                      _c("h4", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.$t("MANAGEMENT.BASIC_INFO")))
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-4 col-form-label",
                            staticStyle: {
                              "font-weight": "bold",
                              "padding-bottom": "30px"
                            },
                            attrs: { for: "username" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MANAGEMENT.USERNAME")) + " "
                            ),
                            _c("span", { staticClass: "required" }, [
                              _vm._v("★")
                            ])
                          ]
                        ),
                        _c(
                          "label",
                          {
                            staticClass: "col-3 col-form-label",
                            staticStyle: {
                              "font-weight": "bold",
                              "padding-bottom": "30px",
                              "word-break": "break-word"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.cookieData.username) + "@ ")]
                        ),
                        _c("div", { staticClass: "col-5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.dataSubAccount.username,
                                expression: "dataSubAccount.username"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "username",
                              name: "username",
                              required: ""
                            },
                            domProps: { value: _vm.dataSubAccount.username },
                            on: {
                              keypress: function($event) {
                                return _vm.clsAlphaNoOnly($event)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.dataSubAccount,
                                  "username",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("small", { staticClass: "text-muted form-text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("MANAGEMENT.TEXT_NUMBER_ONLY"))
                            )
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-1" }),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-5",
                        staticStyle: { "padding-top": "40px" }
                      },
                      [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-4 col-form-label",
                              staticStyle: { "font-weight": "bold" },
                              attrs: { for: "name" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("MANAGEMENT.NAME")) + " "
                              )
                            ]
                          ),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.dataSubAccount.name,
                                  expression: "dataSubAccount.name"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", id: "name", name: "name" },
                              domProps: { value: _vm.dataSubAccount.name },
                              on: {
                                keypress: function($event) {
                                  return _vm.clsAlphaNoOnly($event)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.dataSubAccount,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "text-muted form-text" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("MANAGEMENT.TEXT_NUMBER_ONLY"))
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "form row" }, [
                    _c("div", { staticClass: "form-group col-5" }, [
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-4 col-form-label",
                            staticStyle: { "font-weight": "bold" },
                            attrs: { for: "password" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MANAGEMENT.PASSWORD")) + " "
                            ),
                            _c("span", { staticClass: "required" }, [
                              _vm._v("★")
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "col-8" }, [
                          (_vm.password.default ? "text" : "password") ===
                          "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.dataSubAccount.password,
                                    expression: "dataSubAccount.password"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "password",
                                  name: "password",
                                  required: "",
                                  type: "checkbox"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.dataSubAccount.password
                                  )
                                    ? _vm._i(
                                        _vm.dataSubAccount.password,
                                        null
                                      ) > -1
                                    : _vm.dataSubAccount.password
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.dataSubAccount.password,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.dataSubAccount,
                                            "password",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.dataSubAccount,
                                            "password",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.dataSubAccount,
                                        "password",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              })
                            : (_vm.password.default ? "text" : "password") ===
                              "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.dataSubAccount.password,
                                    expression: "dataSubAccount.password"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "password",
                                  name: "password",
                                  required: "",
                                  type: "radio"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.dataSubAccount.password,
                                    null
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.dataSubAccount,
                                      "password",
                                      null
                                    )
                                  }
                                }
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.dataSubAccount.password,
                                    expression: "dataSubAccount.password"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "password",
                                  name: "password",
                                  required: "",
                                  type: _vm.password.default
                                    ? "text"
                                    : "password"
                                },
                                domProps: {
                                  value: _vm.dataSubAccount.password
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.dataSubAccount,
                                      "password",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c("small", { staticClass: "text-muted form-text" }, [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.PASSWORD_CHECK")))
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "toggle-password field-icon",
                              attrs: { toggle: "#Password" }
                            },
                            [
                              _c("i", {
                                staticClass: "far fa-eye",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleShowPassword("default")
                                  }
                                }
                              })
                            ]
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-1" }),
                    _c("div", { staticClass: "form-group col-5" }, [
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-4 col-form-label",
                            staticStyle: { "font-weight": "bold" },
                            attrs: { for: "phone" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MANAGEMENT.PHONE")) + " "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.dataSubAccount.phone,
                                expression: "dataSubAccount.phone"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", id: "phone", name: "phone" },
                            domProps: { value: _vm.dataSubAccount.phone },
                            on: {
                              keydown: function($event) {
                                return _vm.onlyNumber($event)
                              },
                              keypress: function($event) {
                                return _vm.onlyNumber($event)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.dataSubAccount,
                                  "phone",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("small", { staticClass: "text-muted form-text" }, [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.NUMBER_ONLY")))
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "form row" }, [
                    _c("div", { staticClass: "form-group col-6" }, [
                      _c("h4", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.$t("MANAGEMENT.PERMISSION")))
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-3 col-form-label bold" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MANAGEMENT.ACCOUNT")) + " "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-9 product-option-container" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "product-option",
                                staticStyle: {
                                  "flex-basis": "calc(20% + 10px)"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_ACCOUNT.permission,
                                          expression: "MENU_ACCOUNT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "offAccount",
                                        name: "account",
                                        value: "hidden",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_ACCOUNT.permission,
                                          "hidden"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_ACCOUNT,
                                            "permission",
                                            "hidden"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        staticStyle: { color: "#E84D4D" },
                                        attrs: { for: "offAccount" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.OFF")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_ACCOUNT.permission,
                                          expression: "MENU_ACCOUNT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "viewAccount",
                                        name: "account",
                                        value: "readonly",
                                        checked: "",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_ACCOUNT.permission,
                                          "readonly"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_ACCOUNT,
                                            "permission",
                                            "readonly"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        staticStyle: { color: "#FF8315" },
                                        attrs: { for: "viewAccount" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.VIEW")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_ACCOUNT.permission,
                                          expression: "MENU_ACCOUNT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "editAccount",
                                        name: "account",
                                        value: "editable",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_ACCOUNT.permission,
                                          "editable"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_ACCOUNT,
                                            "permission",
                                            "editable"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: { for: "editAccount" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.EDIT")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm.role == "Company" ||
                      _vm.role == "Shareholder" ||
                      _vm.role == "Master Agent"
                        ? _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              { staticClass: "col-3 col-form-label bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("MANAGEMENT.MEMBER_MANAGEMENT")
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-9 product-option-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "product-option",
                                    staticStyle: {
                                      "flex-basis": "calc(20% + 10px)"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-check form-check-inline"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.MENU_AGENT.permission,
                                              expression:
                                                "MENU_AGENT.permission"
                                            }
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "radio",
                                            id: "offMember",
                                            name: "member",
                                            value: "hidden",
                                            required: ""
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.MENU_AGENT.permission,
                                              "hidden"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.MENU_AGENT,
                                                "permission",
                                                "hidden"
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-check-label",
                                            staticStyle: { color: "#E84D4D" },
                                            attrs: { for: "offMember" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "MANAGEMENT.BUTTON.OFF"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-check form-check-inline"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.MENU_AGENT.permission,
                                              expression:
                                                "MENU_AGENT.permission"
                                            }
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "radio",
                                            id: "viewMember",
                                            name: "member",
                                            value: "readonly",
                                            checked: "",
                                            required: ""
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.MENU_AGENT.permission,
                                              "readonly"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.MENU_AGENT,
                                                "permission",
                                                "readonly"
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-check-label",
                                            staticStyle: { color: "#FF8315" },
                                            attrs: { for: "viewMember" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "MANAGEMENT.BUTTON.VIEW"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-check form-check-inline"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.MENU_AGENT.permission,
                                              expression:
                                                "MENU_AGENT.permission"
                                            }
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "radio",
                                            id: "editMember",
                                            name: "member",
                                            value: "editable",
                                            required: ""
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.MENU_AGENT.permission,
                                              "editable"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.MENU_AGENT,
                                                "permission",
                                                "editable"
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-check-label",
                                            attrs: { for: "editMember" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "MANAGEMENT.BUTTON.EDIT"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-3 col-form-label bold" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MANAGEMENT.REPORT")) + " "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-9 product-option-container" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "product-option",
                                staticStyle: {
                                  "flex-basis": "calc(20% + 10px)"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_REPORT.permission,
                                          expression: "MENU_REPORT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "offReport",
                                        name: "report",
                                        value: "hidden",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_REPORT.permission,
                                          "hidden"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_REPORT,
                                            "permission",
                                            "hidden"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        staticStyle: { color: "#E84D4D" },
                                        attrs: { for: "offReport" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.OFF")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_REPORT.permission,
                                          expression: "MENU_REPORT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "viewReport",
                                        name: "report",
                                        value: "readonly",
                                        checked: "",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_REPORT.permission,
                                          "readonly"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_REPORT,
                                            "permission",
                                            "readonly"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        staticStyle: { color: "#FF8315" },
                                        attrs: { for: "viewReport" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.VIEW")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_REPORT.permission,
                                          expression: "MENU_REPORT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "editReport",
                                        name: "report",
                                        value: "editable",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_REPORT.permission,
                                          "editable"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_REPORT,
                                            "permission",
                                            "editable"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: { for: "editReport" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.EDIT")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "label",
                          { staticClass: "col-3 col-form-label bold" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MANAGEMENT.PAYMENT")) + " "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-9 product-option-container" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "product-option",
                                staticStyle: {
                                  "flex-basis": "calc(20% + 10px)"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_CREDIT.permission,
                                          expression: "MENU_CREDIT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "offPayment",
                                        name: "payment",
                                        value: "hidden",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_CREDIT.permission,
                                          "hidden"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_CREDIT,
                                            "permission",
                                            "hidden"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        staticStyle: { color: "#E84D4D" },
                                        attrs: { for: "offPayment" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.OFF")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_CREDIT.permission,
                                          expression: "MENU_CREDIT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "viewPayment",
                                        name: "payment",
                                        value: "readonly",
                                        checked: "",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_CREDIT.permission,
                                          "readonly"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_CREDIT,
                                            "permission",
                                            "readonly"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        staticStyle: { color: "#FF8315" },
                                        attrs: { for: "viewPayment" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.VIEW")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.MENU_CREDIT.permission,
                                          expression: "MENU_CREDIT.permission"
                                        }
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "radio",
                                        id: "editPayment",
                                        name: "payment",
                                        value: "editable",
                                        required: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.MENU_CREDIT.permission,
                                          "editable"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.MENU_CREDIT,
                                            "permission",
                                            "editable"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: { for: "editPayment" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BUTTON.EDIT")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-group col-6" })
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass: "mb-4",
                    staticStyle: { "text-align": "center" }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-yellow ripple-parent mr-2",
                        attrs: { type: "submit", id: "add-sub-account" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("MANAGEMENT.BUTTON.ADD_SUB_ACCOUNT"))
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }