import { Component, Vue } from "vue-property-decorator";
import Model from "@/models/Model";
import { CREATE_SUB_ACCOUNT_AGENT } from '../../../../api/account';
import { SubAccount, Permission } from '../Component/dto.component';

@Component
default class AddSubAccount extends Vue {

    private displayShow = true
    private cookieData: any = Vue.$cookies.get("login")
    public role = ""
    private model: Model;
    
    private MENU_AGENT: Permission = {
        name: 'MENU_AGENT',
        permission: 'readonly'
    }

    private MENU_ACCOUNT: Permission = {
        name: 'MENU_ACCOUNT',
        permission: 'readonly'
    }

    private MENU_REPORT: Permission = {
        name: 'MENU_REPORT',
        permission: 'readonly'
    }

    private MENU_CREDIT: Permission = {
        name: 'MENU_CREDIT',
        permission: 'readonly'
    }

    private dataSubAccount: SubAccount = {
        username: "",
        name: "",
        password: "",
        phone: "",
        subAccountMenus:[]
    }
    

    private subAccountMenus: Permission[] = []

    private password = {
        default: false
    }

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });

        this.role = this.cookieData.roles
        if (this.cookieData.roles == 'Company' || this.cookieData.roles == 'Shareholder' || this.cookieData.roles == 'Master Agent') {
            this.dataSubAccount.subAccountMenus.push( this.MENU_AGENT)
        }
        this.dataSubAccount.subAccountMenus.push( this.MENU_ACCOUNT, this.MENU_REPORT, this.MENU_CREDIT)
        this.addRouteName()
        loader.hide()
    }

    public toggleShowPassword(name: string): void {
        if (name == 'default') {
            this.password.default = !this.password.default;
        }
    }

    public onlyNumber(event: any): void {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    public clsAlphaNoOnly(event: any): boolean {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public clsAlphaTHNoOnly(event: any): boolean {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[ก-๏a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public changeInputValueInput(key: string, value: string): void {
        this.displayShow = false
        this.dataSubAccount[key] = value
        this.displayShow = true
    }

    public changeInputValue(name: string, permission: string): void {
        const data = {
            name: name,
            permission: permission
        }
        const index = this.subAccountMenus.findIndex((val: any) => val.name == name)
        if (index == -1) {
            this.subAccountMenus.push(data)
        } else {
            this.subAccountMenus[index].permission = permission
        }
        this.dataSubAccount['subAccountMenus'] = this.subAccountMenus
    }
    

    public async save(event: any): Promise<void> {
        event.preventDefault(); 

        this.dataSubAccount['mainAccountId'] = this.cookieData.realUsername
        if (this.dataSubAccount['password'].length < 8) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_CHECK').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        }

        this.$swal({
            title: this.$t('MANAGEMENT.WANT_ADD_SUB_ACC_DATA').toString(),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        }).then(async (swlResult) => {
            if (!swlResult.isConfirmed) {
                return
            }
            
            const result = await CREATE_SUB_ACCOUNT_AGENT(this.dataSubAccount)
            if (result.success) {
                this.$swal({
                    text: this.$t('MANAGEMENT.ADD_SUB_ACC_SUCCESS').toString(),
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    onOpen: () => {
                        this.$swal.isLoading()
                    },
                    onClose: () => {
                        window.location.replace('/member/sub-account');
                    }
                })
            } else {
                this.$swal({
                    text: result.error.message,
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
            }
        })
    }

    private addRouteName(): void {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

}
export default AddSubAccount