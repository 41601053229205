














































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import AddSubAccountComponent from "./AddSubAccount.component";
@Component
export default class AddSubAccount extends Mixins(AddSubAccountComponent) {}
